import { INetworkRequest } from "~src/shared/requests/types";
import { ICountryCode } from "~src/shared/types";

export type IUnauthedRequestLogin = {
  email: string;
  challengeID?: string;
  challengeType?: string;
  userType: string;
};

const makeUnauthedRequest =
  <I, O>(action: string) =>
  (input: I): INetworkRequest<I, O> => ({
    action,
    type: "unauth",
    input,
  });

export const unauthedRequests = {
  // Pinging server
  hello: makeUnauthedRequest<void, void>("hello"),

  // get data from an unverified email signup using a code
  signupsGetInvitedUser: makeUnauthedRequest<
    {
      code: string;
    },
    {
      referralSource: string;
      cachedVendorName: string;
      country: ICountryCode | null;
      firstName: string;
      lastName: string;
      email: string;
    }
  >("signups.get_invited_user"),

  // Company verification and user + vendor creation
  signupsVerifyCompany:
    makeUnauthedRequest<
      {
        code: string;
        name: string;
        country: ICountryCode;
        reportedEstimatedARR: string;
        phone: string;
      },
      void
    >("signups.verify_company"),

  // Investor verification and user + vendor creation
  signupsVerifyInvestor:
    makeUnauthedRequest<
      {
        code: string;
        name: string;
        assetsUnderManagement: string;
        investmentSize: string;
        referral: string;
      },
      void
    >("signups.verify_investor"),

  // Self-serve signup
  selfServeRegister:
    makeUnauthedRequest<
      {
        email: string;
        firstName: string;
        lastName: string;
        password: string;
        referralSource: string | null;
        country: ICountryCode;
        utm: Record<string, string>;
      },
      void
    >("self_serve.register"),

  // ---
  // Authentication
  login:
    makeUnauthedRequest<{ email: string; password: string }, IUnauthedRequestLogin>("auth.login"),
  logout: makeUnauthedRequest<void, void>("auth.logout"),

  // ---
  // Password reset
  /** Intial request for password reset. Sends email with reset link to user. */
  requestPasswordReset: makeUnauthedRequest<{ email: string }, void>("auth.password_reset.request"),
  /** For new password link where user sets new password. */
  passwordReset: makeUnauthedRequest<
    { userPublicID: string; passwordResetCode: string; password: string },
    void
  >("auth.password_reset.reset"),

  // ---
  // Invites
  getInvite:
    makeUnauthedRequest<
      {
        inviteID: string;
        inviteCode: string;
      },
      {
        name: string;
        email: string;
      }
    >("invites.get_info"),
  acceptInvite:
    makeUnauthedRequest<
      {
        inviteID: string;
        inviteCode: string;
        password: string;
      },
      never
    >("invites.register"),

  // 2FA
  verifyTwoFactor:
    makeUnauthedRequest<
      {
        email: string;
        totpCode?: string;
        recoveryCode?: string;
        challengeID: string;
      },
      { email: string }
    >("auth.totp.verify"),
};
