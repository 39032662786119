import { user_type_enum_enum } from "~src/__generated__/graphql/types";
import { ConsoleLog } from "~src/shared/helpers";
import { IGetUserProfileData } from "~src/shared/requests/authedRequests";
import { IVendor } from "~src/shared/types";

export const ALLOWED_REDIRECT_PATHS = [
  "/login",
  "/signup",
  "/link",
  "/welcome",
  "/welcome/company",
  "/welcome/investor",
  "/welcome/investor-waitlist",
  "/inbox",

  "/settings/data-sources",
  "/analytics",
] as const;

export type IAllowedRedirectPath = typeof ALLOWED_REDIRECT_PATHS[number];
export interface IAuthedRedirectOptions {
  /**
   * If true, then non-demo account admins will get redirected to mission control.
   */
  vendorOnly?: boolean;
  /**
   * If true, then one must be authenticated to view this page.
   */
  authedOnly?: boolean;
  /**
   * If true, then one must be an admin to view this page.
   */
  adminOnly?: boolean;
  /**
   * If true, then one must be an investor to view this page.
   */
  investorOnly?: boolean;
}

/**
 * Handles routing for visitors of the app.
 * @returns the path to redirect to. If null, the page is allowed to be accessed.
 */
export const handleAuthedRedirect = ({
  user,
  vendor,
  path: pathWithQuery,
  options,
}: {
  user: IGetUserProfileData | null;
  vendor: IVendor | null;
  path: string;
  options?: IAuthedRedirectOptions;
}): string | null => {
  const path = pathWithQuery.split("?")[0] ?? "";
  // if we require being authed, ensure the user is logged in
  if (options?.authedOnly === true) {
    // If user is not authenticated, redirect to /login
    if (user === null && !path.startsWith("/login")) {
      return "/login";
    }
  }

  // ----------------
  // Admin routing
  if (options?.adminOnly === true) {
    // if not authed, force login
    if (user === null) {
      return "/login";
    }

    // if authed, must be superuser
    if (user.userType !== user_type_enum_enum.pipe_admin) {
      ConsoleLog("User is not an admin, redirecting to /inbox");
      return "/inbox";
    }

    // render the page if we know we are an admin
    return null;
  }
  // ----------------

  if (user === null) {
    return null;
  }

  const doesCurrentPathMatch = (rPath: IAllowedRedirectPath): boolean => {
    return path === rPath;
  };

  const doesCurrentPathMatchAny = (redirectPaths: IAllowedRedirectPath[]): boolean => {
    return redirectPaths.some((redirectPath) => redirectPath === path);
  };

  const isVendorActivated: boolean = vendor?.isActivated === true;

  // ----------------
  // Investor routing
  if (options?.investorOnly === true) {
    if (user.userType !== user_type_enum_enum.investor) {
      return "/login";
    }
  }
  if (user.userType === user_type_enum_enum.investor) {
    if (user.investor !== undefined) {
      if (doesCurrentPathMatch("/inbox")) {
        return "/investor";
      }
      return null;
    }

    if (path.startsWith("/login")) {
      return null;
    }

    // Temporarily redirect all investors to the investor waitlist page
    return "/welcome/investor-waitlist";
  }
  // ----------------

  // ----------------
  // Vendor routing
  // If this is a vendor-only page, ensure that we are not an admin.
  if (options?.vendorOnly === true) {
    // Route non-demo account Pipe admins to /admin
    if (user.userType === user_type_enum_enum.pipe_admin) {
      return "/admin";
    }

    /** Whether vendor has attempted to connect any account.
     * True even if the connection is in an error state.
     */
    // If user is logged in already, dont let them go to /login or /signup
    if (doesCurrentPathMatch("/login") || doesCurrentPathMatch("/signup")) {
      return "/inbox";
    }

    // ACTIVATED VENDORS
    if (isVendorActivated) {
      // Block from visiting /welcome page
      if (doesCurrentPathMatch("/welcome")) {
        return "/inbox";
      }
      // Otherwise, let them through
      return null;
    }

    // UNACTIVATED VENDORS

    // --- FOR NEW DATA SOURCES FLOWS ---
    // Handle the case where:
    //  - Vendor is not activated.
    //  - They are on the data sources page to connect their bank/accounting/billing.
    //
    // Some data source flows are redirect-based e.g. Codat in which case after the
    // sucessful link on the Codat side, the vendor is taken back to the initial "source"
    // page where they initiated the flow from. Redirect uri which looks something like
    // "https://.../settings/data-sources?codat=true" is parsed and  the success screen/dialog
    // is shown to the user.
    //
    // Currently the data sources flows can be initiated from multiple places:
    // - /settings/data-sources (Data Sources page)
    // - /analytics (Home Dashboard page)
    // - /inbox (Trade page)
    //
    // Because we want to take the user back to the initial screen they started
    // the connection flow from, we check the current path for currently allowed
    // places and redirect them back to there.
    // Otherwise, un-activated vendor who starts their flow on the Home or Data Sources
    // pages, upon a successful (e.g. Codat link) is redirected to /inbox which we
    // dont want.
    if (doesCurrentPathMatchAny(["/settings/data-sources", "/analytics"])) {
      // It's important we return query string along with the path so the
      // destination pages can parse and act on it.

      // pathWithQuery will be e.g:
      // - /settings/data-sources?codat=true,
      // - /analytics?stripe=true
      // - etc.
      return pathWithQuery;
    }

    // Otherwise, let them view the requested page
    return null;
  }

  // ----------------

  return null;
};
