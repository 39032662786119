import { NextPage } from "next";
import React from "react";

import { withApollo } from "~src/shared/apollo/withApollo";
import { AuthProvider, IPageAuthorization } from "~src/shared/auth/AuthProvider";
import { CommandKProvider } from "~src/shared/commandK/contexts/CommandKProvider";
import { useEnv } from "~src/shared/env/useEnv";
import { useInstallScriptCode } from "~src/shared/helpers/hooks";
import { IPipeRouter } from "~src/shared/requests/router";
import { Maintenance } from "~src/unauthed/maintenance/page";

import { NewVersionCheck } from "../newVersionCheck";

interface IMakeAuthHOC {
  /**
   * Name of the HOC.
   */
  name: string;
  /**
   * The router for Apollo client to use.
   */
  apolloRouter: IPipeRouter;
  /**
   * Authorization rule for the HOC.
   */
  authorization: IPageAuthorization;
}

/**
 * Creates an Auth HOC, which injects Apollo into the page and ensures the proper authorization was met.
 * @param name
 * @param apolloEndpoint
 */
export const makeAuthHOC = ({ name, apolloRouter, authorization }: IMakeAuthHOC) => {
  // TODO(igm): this any type is required by sentry
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <P extends Record<string, any>>(WrappedComponent: NextPage<P>): NextPage<P> => {
    const FullyWrapped = withApollo(apolloRouter)(WrappedComponent);

    const InnerWrapper: React.FC<P> = (props: P) => {
      const env = useEnv();
      useInstallScriptCode(env.SEGMENT_SNIPPET, "segment");
      // When scheduled maintenance is triggered via envar, display Maintenance Page.
      if (env.MODE === "maintenance") {
        return <Maintenance />;
      }

      return <FullyWrapped {...props} />;
    };

    const Wrapper: React.FC<P> = (props: P) => (
      <CommandKProvider>
        <AuthProvider authorization={authorization}>
          <InnerWrapper {...props} />
          <NewVersionCheck />
        </AuthProvider>
      </CommandKProvider>
    );

    Wrapper.displayName = `${name}(${WrappedComponent.displayName ?? "Anonymous"})`;

    return Wrapper;
  };
};
