/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";

import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import {
  OldHeadingUseTextInstead,
  OldSubheadingUseTextInstead,
} from "~src/designSystem/deprecated/Heading";
import { t } from "~src/designSystem/theme";
import { MaintenanceImg } from "~src/shared/animations/MaintenanceImg";
import { ThemeProvider } from "~src/shared/darkMode/ThemeProvider";

export const Maintenance: React.FC = () => (
  <ThemeProvider force="dark">
    <PleaseGoUseFlexInstead
      fullWidth
      alignItems="center"
      justifyContent="center"
      css={css`
        height: 100vh;
        background: ${t.colors.surfaceBackground.toString()};
      `}
    >
      <MaintenanceImg />
      <PleaseGoUseFlexInstead padY alignItems="center">
        <OldHeadingUseTextInstead
          css={css`
            color: white;
          `}
        >
          Pipe is currently down for scheduled maintenance.
        </OldHeadingUseTextInstead>
        <OldSubheadingUseTextInstead>
          We expect to be back in a few hours. Thanks for your patience.
        </OldSubheadingUseTextInstead>
      </PleaseGoUseFlexInstead>
    </PleaseGoUseFlexInstead>
  </ThemeProvider>
);
