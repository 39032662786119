import styled from "@emotion/styled";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import create from "zustand/vanilla";

import { user_type_enum_enum } from "~src/__generated__/graphql/types";
import { Button } from "~src/designSystem/atoms/Button";
import { t } from "~src/designSystem/theme";

import { useMaybeUser } from "../auth/useUser";
import { useEnv } from "../env/useEnv";
import { logError } from "../helpers";

// 15 minutes. Check for a new version on this interval.
const CHECK_INTERVAL_MS = 15 * 60 * 1000;
// 20 minutes. Show the presentation after this time has passed.
const SHOW_AFTER_MS = 20 * 60 * 1000;

type IVersionStore = {
  previousCheckTime: number;
  newVersionSince: number | null;
};

// We want to keep track of when we checked this even across remounts. So we store the
// state of the previous check time here.
const versionStore = create<IVersionStore>(() => ({
  previousCheckTime: Date.now(),
  newVersionSince: Date.now(),
}));

// NewVersionCheck checks to see if we have a new version of the frontend, and if so,
// asks the user to refresh if 30 minutes elapse on the old version.
//
// We currently have this enabled only for admins. Before we roll this out to vendors,
// we should maybe consider using the Toaster for this instead of a custom toast.
export const NewVersionCheck: React.FC = () => {
  const user = useMaybeUser();
  const env = useEnv().NODE_ENV;

  if (user === null || user.userType !== user_type_enum_enum.pipe_admin || env === "development") {
    return null;
  }

  return <TheRealVersionCheck />;
};

const TheRealVersionCheck: React.FC = () => {
  useEffect(() => {
    const theCheck = async () => {
      // This checks for a new version and updates the state store.
      await checkForNewVersion();
      // This handles the presentation if there is a new version.
      displayNewVersion();
    };

    // We run the check every 15 seconds, and the function exits early if
    // CHECK_INTERVAL_MS hasn't passed.
    const id = setInterval(theCheck, 15_000);
    return () => clearInterval(id);
  }, []);

  return null;
};

const checkForNewVersion = async () => {
  const { newVersionSince, previousCheckTime } = versionStore.getState();
  // Exit early if we know there is a new version.
  if (newVersionSince !== null) {
    return;
  }
  // Exit early if check interval has not yet passed since lsat check.
  if (Date.now() - previousCheckTime < CHECK_INTERVAL_MS) {
    return;
  }

  try {
    const apiVersion = await fetchApiVersion();
    if (apiVersion !== COMMIT_SHA) {
      versionStore.setState({ newVersionSince: Date.now() });
    }
  } catch (e) {
    logError(e);
  } finally {
    versionStore.setState({ previousCheckTime: Date.now() });
  }
};

const displayNewVersion = () => {
  const { newVersionSince } = versionStore.getState();
  if (newVersionSince === null || Date.now() - newVersionSince < SHOW_AFTER_MS) {
    return;
  }

  toast.custom(
    <NewVersionWrapper>
      <div>A new version of the application is available.</div>
      <Button size="small" onClick={() => window.location.reload()}>
        Reload
      </Button>
    </NewVersionWrapper>,
    {
      id: "new-version-check",
      position: "bottom-center",
      duration: Infinity,
    },
  );
};

const fetchApiVersion = async (): Promise<string> => {
  const res = await fetch("/api/version");
  const { version } = await res.json();
  return version;
};

const NewVersionWrapper = styled.div`
  display: flex;
  align-items: center;

  background-color: ${(props) => props.theme.components.Toast.Background};
  width: fit-content;

  padding: ${t.c.spacing("2", "3")};
  border-radius: ${t.radii[4].toString()};
  gap: ${t.space[4].toString()};
  color: ${(props) => props.theme.components.Toast.Text};
`;
