import styled, { CSSObject } from "@emotion/styled";
import { OverlayContainer, useOverlayPosition } from "@react-aria/overlays";
import { useTooltip, useTooltipTrigger } from "@react-aria/tooltip";
import { mergeProps } from "@react-aria/utils";
import { TooltipTriggerState, useTooltipTriggerState } from "@react-stately/tooltip";
import { TooltipTriggerProps } from "@react-types/tooltip";
import { AnimatePresence, motion } from "framer-motion";
import React, { useMemo } from "react";

import { usePopoverMotionProps } from "~src/designSystem/atoms/Popover";
import { IPopoverPlacement } from "~src/designSystem/atoms/Popover/types";
import { Portal } from "~src/designSystem/atoms/Portal";
import { t } from "~src/designSystem/theme";

export type ITooltipProps = TooltipTriggerProps & {
  className?: string;

  /**
   * Where to render the tooltip.
   *
   * @default top
   */
  placement?: IPopoverPlacement;

  /**
   * The tooltip trigger.
   */
  children: React.ReactElement;

  /**
   * What's rendered in the tooltip.
   */
  tooltipContent: React.ReactNode;

  textAlign?: CSSObject["textAlign"];
  maxWidth?: string;

  bgColor?: string;
  borderRadius?: string;
};

/**
 * This is our Design System tooltip component.
 *
 * See https://react-spectrum.adobe.com/react-aria/useTooltipTrigger.html for props.
 */
export const Tooltip: React.FC<ITooltipProps> = (props) => {
  // We override the default delay of react-aria here.
  const wrappedProps = React.useMemo(
    () => ({
      ...props,
      delay: props.delay ?? 0,
      placement: props.placement ?? ("top" as const),
      textAlign: props.textAlign ?? "center",
      maxWidth: props.maxWidth ?? "240px",
    }),
    [props],
  );

  const triggerRef = React.useRef(null);
  const overlayRef = React.useRef(null);

  const state = useTooltipTriggerState(wrappedProps);

  const { triggerProps: triggerPropsRaw, tooltipProps } = useTooltipTrigger(
    wrappedProps,
    state,
    triggerRef,
  );

  // We delete onClick from trigger props because it's interfering with tooltipped click
  // elements. The onClick prop is not advertised in the Press/Hover/Focus props in
  // react-aria's API. Tooltip feature is also only active in hover/focus.
  const triggerProps = useMemo(() => {
    const r = { ...triggerPropsRaw };
    delete r.onClick;
    return r;
  }, [triggerPropsRaw]);

  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement: wrappedProps.placement,
    offset: 12,
    isOpen: state.isOpen,
    shouldFlip: false,
  });

  const motionProps = usePopoverMotionProps(wrappedProps.placement);

  // Some consumers of the old antd tooltip were passing in null. Even though it's not a
  // part of the type signature, we handle it here.
  if (props.tooltipContent == null) {
    return <>{props.children}</>;
  }

  return (
    <>
      {React.cloneElement(props.children, { ref: triggerRef, ...triggerProps })}
      <AnimatePresence>
        {state.isOpen && (
          <OverlayContainer>
            <Portal
              {...tooltipProps}
              {...positionProps}
              ref={overlayRef}
              isOpen={state.isOpen}
              onClose={state.close}
              isDismissable
            >
              <motion.div key="portal-content" {...motionProps}>
                <TooltipPopover
                  state={state}
                  textAlign={wrappedProps.textAlign}
                  maxWidth={wrappedProps.maxWidth}
                  bgColor={wrappedProps.bgColor}
                  borderRadius={wrappedProps.borderRadius}
                >
                  {props.tooltipContent}
                </TooltipPopover>
              </motion.div>
            </Portal>
          </OverlayContainer>
        )}
      </AnimatePresence>
    </>
  );
};

export type ITooltipPopoverProps = React.HTMLAttributes<HTMLElement> & {
  state: TooltipTriggerState;
  textAlign?: CSSObject["textAlign"];
  maxWidth?: string;

  bgColor?: string;
  borderRadius?: string;
};

export const TooltipPopover: React.FC<ITooltipPopoverProps> = (props) => {
  const { tooltipProps } = useTooltip(props, props.state);

  return (
    <TooltipPopoverWrapper {...mergeProps(props, tooltipProps)}>
      {props.children}
    </TooltipPopoverWrapper>
  );
};

const TooltipPopoverWrapper = styled.div<ITooltipPopoverProps>`
  ${(props) => props.theme.textStyles.Regular.Body50};
  padding: ${t.c.spacing("2", "3")};
  width: fit-content;
  max-width: ${(props) => props.maxWidth};

  opacity: 1;
  border-radius: ${(props) =>
    props.borderRadius != null ? props.borderRadius : t.radii[2].toString()};

  background-color: ${(props) =>
    props.bgColor != null ? props.bgColor : props.theme.components.Tooltip.background};

  /* Do we really want this? I'm maintaining this from the antd tooltip. */
  text-align: ${(props) => props.textAlign};
`;
