export enum IDataType {
  boolean = "boolean",
  currency = "currency",
  numeric = "numeric",
  text = "text",
  timestamp = "timestamp",
}

export enum IRenderType {
  boolean = "boolean",
  cents = "cents", // minor units
  currency = "currency", // ISO 4217 currency code
  date = "date",
  email = "email",
  geo = "geo",
  human = "human",
  id = "id", // a public id in the database
  monetary = "monetary", // major units
  numeric = "numeric",
  percent = "percent",
  text = "text",
  timestamp = "timestamp",
  url = "url",
}
