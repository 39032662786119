import { IStatusTagColor, StatusTag } from "~src/designSystem/atoms/StatusTag";
import { Format } from "~src/shared/formatters";
import { formatCents } from "~src/shared/helpers/format";
import { dateTimeWithTimezoneFmt } from "~src/shared/helpers/formatDate";
import { IRenderType } from "~src/shared/lists/types/types";

import { ITableRenderer, ITableRendererProps, ITableRenderType } from "../types";

const noValue = (v: unknown): boolean => {
  return v === null || v === undefined || (v as { toString(): string }).toString().trim() === "";
};

export function makeRenderer<T>(
  render: (v: T, props: ITableRendererProps<T>) => React.ReactElement,
): ITableRenderer<T> {
  return (props: ITableRendererProps<T>): React.ReactElement => {
    const { row, value } = props;
    const v = value(row);

    if (noValue(v)) {
      return <>--</>;
    }

    return render(v, props);
  };
}

const TextRenderer = makeRenderer<string>((v) => {
  return <>{v}</>;
});

const NumericRenderer = makeRenderer<number>((v) => {
  return <>{String(v)}</>;
});

const PercentRenderer = makeRenderer<number>((v) => {
  return <>{`${v.toFixed(2)}%`}</>;
});

const BooleanRenderer = makeRenderer<boolean>((v) => {
  let color;
  if (v) {
    color = "green" as IStatusTagColor;
  } else {
    color = "red" as IStatusTagColor;
  }

  return <StatusTag color={color} label={String(v)} />;
});

const CentsRenderer = makeRenderer<number>((v, { row }) => {
  // TODO(usmanm): better way to pass currency column
  const currency = (row.data.currency ?? "usd") as string;
  const formatted = formatCents(v, currency);

  return <>{formatted}</>;
});

const CurrencyRenderer = makeRenderer<string>((v) => {
  return <>{v.toUpperCase()}</>;
});

const DateRenderer = makeRenderer<string>((v) => {
  return <Format.Date noTooltip date={v} />;
});

// TODO
const EmailRenderer = TextRenderer;
const GeoRenderer = TextRenderer;
const HumanRenderer = TextRenderer;
const MonetaryRenderer = NumericRenderer;
const TimestampRenderer = makeRenderer<string>((v) => {
  return <Format.Date date={v} formatter={dateTimeWithTimezoneFmt} />;
});

const UrlRenderer = makeRenderer<string>((v) => {
  return (
    <a target="_blank" href={v}>
      {v}
    </a>
  );
});

const IDRenderer = makeRenderer<string>((v) => {
  return (
    <a target="_blank" href={`/admin/developer/object/${v}`}>
      {v}
    </a>
  );
});

export const renderers: Record<ITableRenderType, ITableRenderer<unknown>> = {
  [IRenderType.id]: IDRenderer,
  [IRenderType.boolean]: BooleanRenderer,
  [IRenderType.cents]: CentsRenderer,
  [IRenderType.currency]: CurrencyRenderer,
  [IRenderType.date]: DateRenderer,
  [IRenderType.email]: EmailRenderer,
  [IRenderType.geo]: GeoRenderer,
  [IRenderType.human]: HumanRenderer,
  [IRenderType.monetary]: MonetaryRenderer,
  [IRenderType.numeric]: NumericRenderer,
  [IRenderType.percent]: PercentRenderer,
  [IRenderType.text]: TextRenderer,
  [IRenderType.timestamp]: TimestampRenderer,
  [IRenderType.url]: UrlRenderer,
};
