import { FocusScope } from "@react-aria/focus";
import { DismissButton, useOverlay } from "@react-aria/overlays";
import { mergeProps } from "@react-aria/utils";
import React from "react";

import { ThemeProvider } from "~src/shared/darkMode/ThemeProvider";

// The types are copied from react-aria.
export type IPortalPlacement =
  | "bottom"
  | "bottom left"
  | "bottom right"
  | "bottom start"
  | "bottom end"
  | "top"
  | "top left"
  | "top right"
  | "top start"
  | "top end"
  | "left"
  | "left top"
  | "left bottom"
  | "start"
  | "start top"
  | "start bottom"
  | "right"
  | "right top"
  | "right bottom"
  | "end"
  | "end top"
  | "end bottom";

export type IPortalProps = {
  /* Overlay props */

  /**
   * Whether the overlay is currently open.
   */
  isOpen?: boolean;
  /**
   * Handler that is called when the overlay should close.
   */
  onClose?: () => void;
  /**
   * Whether to close the overlay when the user interacts outside it.
   */
  isDismissable: boolean;
  /**
   * Whether the overlay should close when focus is lost or moves outside it.
   * @default false
   */
  shouldCloseOnBlur?: boolean;
  /**
   * Whether pressing the escape key to close the overlay should be disabled.
   * @default true
   */
  isKeyboardDismissDisabled?: boolean;

  /* Portal props */

  children: React.ReactChild;
};

/**
 * Portal is a generic overlay component for internal use by Dialog, Select, etc. It is
 * not intended to be used directly.
 *
 * See the react-aria docs here:
 * https://react-spectrum.adobe.com/react-aria/useOverlayTrigger.html.
 */
export const Portal = React.forwardRef(
  (props: IPortalProps, ref: React.RefObject<HTMLDivElement>) => {
    const {
      onClose,
      isOpen,
      isDismissable,
      shouldCloseOnBlur = false,
      isKeyboardDismissDisabled = true,
      children,
      ...otherProps
    } = props;

    const { overlayProps } = useOverlay(
      { onClose, isOpen, isDismissable, shouldCloseOnBlur, isKeyboardDismissDisabled },
      ref,
    );

    return (
      <ThemeProvider>
        <FocusScope restoreFocus>
          <div {...mergeProps(overlayProps, otherProps)} ref={ref}>
            {children}
            <DismissButton onDismiss={onClose} />
          </div>
        </FocusScope>
      </ThemeProvider>
    );
  },
);
