/* eslint-disable react/style-prop-object */
import React from "react";

export const IconConfetti: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 19">
      <path
        fill="#fff"
        d="M4.543 1.074L5.436.95l.66 4.756-.894.124-.66-4.756zM6.47 8.096l-4.558-1.51.283-.856 4.558 1.51-.284.856zM2.517 9.965l.617-.447-.73-.218-.235-.724-.432.627H.975l.462.604-.235.725.719-.254.616.449-.02-.762zM2.498 2.925c0 .921-1.383.921-1.383 0 0-.922 1.383-.922 1.383 0zM8.899 4.298c0 .921-1.383.921-1.383 0 0-.922 1.383-.922 1.383 0zM15.975 17.007l-4.04-11.441a.757.757 0 00-.557-.496.759.759 0 00-.717.207l-7.41 7.41a.759.759 0 00-.207.716c.06.264.24.468.496.557l11.455 4.044c.28.1.577.035.792-.172a.754.754 0 00.201-.783l-.013-.042z"
      />
    </svg>
  );
};
