/* eslint-disable react/style-prop-object */
import React from "react";

type IProps = {
  "aria-label"?: string;
};

export const PipeWhite: React.FC<IProps> = (props) => (
  <svg
    width="178px"
    height="241px"
    viewBox="0 0 178 241"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Pipe Logo</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Asset-1-Copy-2"
        transform="translate(-1.000000, 0.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(0.968889, -0.002266)">
          <path
            d="M0.0311111111,240.782266 L0.0311111111,236.842266 C0.0311111111,187.522266 -0.0388888889,138.202266 0.0311111111,88.842266 C0.0811111111,63.342266 17.0311111,42.332266 41.8311111,38.542266 C52.7311111,36.882266 64.0811111,38.262266 75.9711111,38.262266 L75.9711111,42.412266 C75.9711111,90.8989326 75.9711111,139.385599 75.9711111,187.872266 C75.9711111,218.872266 54.1511111,240.622266 23.1611111,240.732266 C15.7311111,240.802266 8.24111111,240.782266 0.0311111111,240.782266 Z"
            id="Path"
          />
          <path
            d="M101.431111,152.472266 L101.431111,0.00226596187 C115.491111,0.702265962 129.501111,-1.67773404 143.091111,3.00226596 C163.104715,9.75830771 176.713609,28.3624668 177.091111,49.482266 C177.361111,66.482266 177.251111,83.482266 177.181111,100.482266 C177.051111,129.482266 157.661111,150.482266 128.801111,152.482266 C119.861111,153.002266 110.861111,152.472266 101.431111,152.472266 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);
